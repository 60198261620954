//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAb25Az6R91qObsuxswlNIIS9gAsVAYxhY",
  authDomain: "test123-d4152.firebaseapp.com",
  projectId: "test123-d4152",
  storageBucket: "test123-d4152.appspot.com",
  messagingSenderId: "802903054617",
  appId: "1:802903054617:web:bb465d06fc667095880d88",
  measurementId: "",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
